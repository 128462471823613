html, body{
  min-height: 100vh;
  max-width: 100vw;
  color: #f6f6f6;
}

#root{
  min-height: 100vh;
}

.App {
  display: flex;
  text-align: center;
  height: 100vh;
  color: #000074;
  justify-content: center;
  align-items: center;
  font-family: 'Kanit', sans-serif;
}

#splashpage_container{
  display: flex;
  height: 90vh;
  align-items: center;
  align-content: center;
}

#title{
  font-size: 5em;
}

#blurb{
  font-size: 3.2em;
}

#footer{
  text-align: center;
  position: fixed;
  top: 95vh;
  height: 5vh;
  width: 100vw;
  font-size: 1.2  sem;
}

#background_image{
  position: fixed;
  z-index: -2;
  width: 100%;
  height: auto;
  background-size: cover;
}

#video_background{

  position: fixed;
  z-index: -1;
  width: 100%;
  height: auto;
 }

 .container{
  margin: 0;
 }

 #contact_us{
  text-decoration: none;
  color: #000074;
}

@media(min-aspect-ratio: 16/9){
  #video_background{
    width: 100%;
    height: auto;
  }
}

@media(max-aspect-ratio: 16/9){
  #video_background{
    width: auto;
    height: 100%;
  }

  @media (max-width: 767px) {
    #video_background{
        display: none;
    }
    .App{
      background: url("./images/background5.png");
      background-size: cover;
    }

    #title{
      font-size: 6vw;
    }
    
    #blurb{
      font-size: 4vw;
    }

    #footer{
      font-size: 2vw;
    }
  }
}